enum ProductLabel {
  CertificateIsChecked = 'Проверено СГР',
  ChoiceOfItab = 'Выбор iTAB',
}

const { CertificateIsChecked, ChoiceOfItab } = ProductLabel

namespace ProductLabel {
  export function hasDescription (productLabel: ProductLabel): boolean {
    return [CertificateIsChecked, ChoiceOfItab].includes(productLabel)
  }
}

export default ProductLabel
