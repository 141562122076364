<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    isProductPage?: boolean
    rating: number | null
  }>(),
  { isProductPage: false }
)

const formattedRating = computed(() => String(Math.round(10 * (props.rating as number)) / 10).replace('.', ','))
</script>

<template>
  <div
    v-if="rating !== null"
    class="
      flex h-[22px] items-center rounded-[6px] bg-main-yellow px-[6px] text-xs text-white
      md:h-9 md:rounded-xl md:px-3 md:text-sm
    "
    :class="{ '!h-6': isProductPage }"
  >
    <SvgIcon class="mr-0.5" name="outlined/star" />
    <span class="font-medium">{{ formattedRating }}</span>
  </div>
</template>
