<script lang="ts" setup>
import BasicButton from '~/components/form-elements/BasicButton.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import Page from '~/enums/page'
import ProductLabel from '~/enums/product-label'

const model = defineModel<boolean>()

const props = defineProps<{
  productLabel: ProductLabel
}>()

function close (): void {
  model.value = false
}
</script>

<template>
  <CustomModalWindow v-model="model">
    <template #title>
      {{ productLabel }}
    </template>

    <p>
      <template v-if="productLabel === ProductLabel.CertificateIsChecked">
        Товар является БАД, для этой категории необходимо свидетельство о&nbsp;государственной регистрации (СГР). Комиссия iTAB проверила документы в&nbsp;госреестре.
      </template>
      <template v-else-if="productLabel === ProductLabel.ChoiceOfItab">
        Товар прошёл проверку экспертно-медицинской комиссией и&nbsp;соответствует принципам iTAB. Подробнее об&nbsp;этапах проверки и&nbsp;принципах <a :href="Page.ExpertMedicalCommission" target="_blank">здесь</a>.
      </template>
    </p>

    <BasicButton color="green" title="Понятно" @click="close" />
  </CustomModalWindow>
</template>
